
import Vue, { PropType } from "vue"
import type { Segment, SegmentPolygonCoords } from "@evercam/shared/types"
import SegmentPolygon from "@evercam/shared/components/SegmentPolygon.vue"
import { TimelineColors } from "@evercam/shared/constants/timeline.ts"

export default Vue.extend({
  name: "ObjectInspectorOverlay",
  components: {
    SegmentPolygon,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    segments: {
      type: Array as PropType<Segment[]>,
      required: true,
    },
    selectedSegment: {
      type: [Object, undefined] as PropType<Segment | undefined>,
      default: undefined,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSegmentPosition: null as { left: number; top: number } | null,
    }
  },
  computed: {
    TimelineColors(): Record<string, string> {
      return TimelineColors
    },
    showControls(): boolean {
      return this.selectedSegment && this.selectedSegmentPosition
    },
    controlsStyles(): Record<string, unknown> {
      if (!this.selectedSegmentPosition) {
        return {}
      }

      const containerRect = (
        this.$refs.container as HTMLElement
      ).getBoundingClientRect()

      return {
        left: `${this.selectedSegmentPosition.left - containerRect.left}px`,
        top: `${this.selectedSegmentPosition.top - containerRect.top - 48}px`,
      }
    },
    selectedSegmentPolygon(): SegmentPolygonCoords | undefined {
      if (!this.selectedSegment) {
        return
      }

      return this.getDenormalizedCoordinates(this.selectedSegment)
    },
  },
  watch: {
    selectedSegment(v) {
      if (!v) {
        this.selectedSegmentPosition = null

        return
      }

      this.updateSelectedSegmentPosition()
    },
  },
  methods: {
    updateSelectedSegmentPosition() {
      const el = this.$refs.selectedSegmentPolygon as HTMLElement
      if (!el) {
        return this.$setTimeout(this.updateSelectedSegmentPosition, 100)
      }

      this.selectedSegmentPosition = {
        left: el.getBoundingClientRect().left,
        top: el.getBoundingClientRect().top,
      }
    },
    getDenormalizedCoordinates(segment: Segment): SegmentPolygonCoords {
      return segment.mask
        .map((point) => {
          const [x, y] = point

          return `${Number.parseInt(x * this.width)},${Number.parseInt(
            y * this.height
          )}`
        })
        .join(" ")
    },
  },
})
