
import Vue, { PropType } from "vue"
import { type DetectionLabel, SegmentLabel } from "@evercam/shared/types"
import {
  hexToRgb,
  pickEvenlySpacedItems,
  stringToColour,
} from "@evercam/shared/utils.ts"

export default Vue.extend({
  name: "SegmentPolygon",
  props: {
    label: {
      type: String as PropType<DetectionLabel>,
      required: true,
    },
    points: {
      type: Array as PropType<number[][]>,
      required: true,
    },
    imageWidth: {
      type: Number,
      required: true,
    },
    imageHeight: {
      type: Number,
      required: true,
    },
    showOnHover: {
      type: Boolean,
      default: false,
    },
    hideUnknownLabel: {
      type: Boolean,
      default: false,
    },
    baseColor: {
      type: [String, undefined],
      default: undefined,
    },
    hoverColor: {
      type: [String, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      isHighlighted: false,
    }
  },
  computed: {
    showLabel(): boolean {
      if (!this.isHighlighted) {
        return false
      }

      return this.label !== SegmentLabel.Unknown || !this.hideUnknownLabel
    },
    polygonStyle(): Record<string, unknown> {
      return {
        fill: this.isHighlighted
          ? this.getRgbaColor(0.5)
          : this.getRgbaColor(0.2),
        stroke: this.color,
        strokeWidth: this.isHighlighted ? "2px" : "1px",
      }
    },
    labelStyle(): Record<string, unknown> {
      return {
        fill: "#fff",
        stroke: "#333",
        strokeWidth: this.isHighlighted ? "1px" : "0",
      }
    },
    hexColor(): string {
      if (this.isHighlighted) {
        return this.hoverColor || stringToColour(this.label)
      } else {
        return this.baseColor || stringToColour(this.label)
      }
    },
    color(): string {
      return this.getRgbaColor(1)
    },
    pointsString(): string {
      return this.points
        .map(
          ([x, y]) =>
            `${(x * this.imageWidth).toFixed(0)},${(
              y * this.imageHeight
            ).toFixed(0)}`
        )
        .join(" ")
    },
    labelPosition(): { x: number; y: number } {
      if (!this.points || this.points.length === 0) {
        return { x: 0, y: 0 }
      }

      const [minX, minY] = pickEvenlySpacedItems(this.points, 10).reduce(
        ([accX, accY], [x, y]) => [
          Math.min(accX, x * this.imageWidth).toFixed(0),
          Math.min(accY, y * this.imageHeight).toFixed(0),
        ],
        [this.imageWidth, this.imageHeight]
      )

      return {
        x: Math.min(Math.max(minX, 20), this.imageWidth - 75),
        y: Math.min(Math.max(minY - 10, 20), this.imageHeight - 75),
      }
    },
  },
  methods: {
    getRgbaColor(opacity: number): string {
      const { r, g, b } = hexToRgb(this.hexColor)

      return `rgba(${r},${g},${b}, ${opacity})`
    },
  },
})
